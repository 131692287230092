<template>
  <van-overlay
      :show="loadingOfWorkorderDetails">
    <div class="wrapper" @click.stop>
      <van-loading type="spinner" color="#1989fa"/>
    </div>
  </van-overlay>
  <div>
    <van-nav-bar fixed border left-arrow
                 title="待指派工单详情页"
                 left-text="返回"
                 @click-left="router.push({path:'/',query})"/>

    <div class="page-container">
      <van-cell-group inset title="客户信息">
        <van-field label="客户姓名" readonly :model-value="workorder.workorder.clientName"/>
        <van-field label="客户电话" readonly :model-value="workorder.workorder.clientCellphone"/>
        <van-field label="客户单位" readonly :model-value="workorder.workorder.clientDepartmentName"/>
        <van-field label="客户地址" readonly :model-value="workorder.workorder.clientDepartmentAddress"/>
      </van-cell-group>

      <van-cell-group inset title="工单信息">
        <van-field label="预约时间" readonly :model-value="workorder.workorder.appointmentTime"/>
        <van-field label="创建时间" readonly :model-value="workorder.workorder.createTime"/>
        <van-field label="计划时间" readonly :model-value="workorder.workorder.deadLine"/>
        <van-field label="优先级" readonly model-value="中"/>
        <van-field label="工单状态" readonly model-value="待指派"/>
      </van-cell-group>
    </div>
  </div>
</template>

<script setup>
//TODO 待指派工单详情页
import {useRouter} from "vue-router";
import {onMounted, reactive, ref} from "vue";
import {Toast} from "vant";
import {nameEncryption, phoneEncryption} from "../../../plugin/encryption";

import {requestDispatchWorkorder} from "@/api/workorder";

const router = useRouter();

const query = getUrlParam();
const workorder = reactive({workorder: {}});
const loadingOfWorkorderDetails = ref(false);

const requestWorkorderInfo = () => {
  loadingOfWorkorderDetails.value = true;
  requestDispatchWorkorder(query.workorderId).then(res => {
    res.data.clientName = nameEncryption(res.data.clientName);
    res.data.clientCellphone = phoneEncryption(res.data.clientCellphone);
    workorder.workorder = res.data;
    loadingOfWorkorderDetails.value = false;
  }).catch(err => {
    loadingOfWorkorderDetails.value = false;
    console.error("request workorder info failed", err);
    if (err.status === 40400) {
      Toast.fail({
        message: "暂无查询到此工单的信息",
        forbidClick: true
      });
    }
  });
};

onMounted(() => {
  validationUrlParam();
  const workorderId = getUrlParam("workorderId");
  if (!workorderId) {
    router.back();
    Toast.fail({
      message: "暂无查询到工单ID",
      forbidClick: true
    });
  }
  requestWorkorderInfo();
});
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
